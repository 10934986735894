import React, { Component } from "react";

class About extends Component {
  render() {
    return (
      <div className="condiv">
        <h1 className="subtopic"> About Me</h1>
        <h4> Hey There, </h4>
        <h1> I'm Levi Glendenning</h1>
        <h3>
          Full Stack Web <u>Developer</u> | UI/UX <u>Designer</u>
        </h3>
        <br></br>
        <p>
          I started my journey in the world of web development in college.
          Working with HTML and JavaScript, getting a degree in Management and
          Marketing. In 2018, I decided to go back to school and enter into a <b>Full stack</b> Web Development Boot camp learning the <b>MERN</b> stack. Now I like to center my interests in Web Development, I like to
          create interactive UI components for better designs and use the latest
          in front-end web technology to show my abilities. I enjoy working with
          React, JavaScript, jQuery and CSS to demonstrate my skills.
        </p>
      </div>
    );
  }
}
export default About;
